export const properties = {
};

export const firebaseConfig = {
  apiKey: 'AIzaSyB5AlmeCL3MYEDyybr6CpfjrM7blFx3JUM',
  authDomain: 'wpsender.org',
  projectId: 'whatsapp-messages-sender-b804e',
  storageBucket: 'whatsapp-messages-sender-b804e.appspot.com',
  messagingSenderId: '116839280050',
  appId: '1:116839280050:web:0c0ebb1162d064181688ab',
  measurementId: 'G-NZGETGM9GZ',
};
